import { ExtendedIntersectionObserverEntry } from './extended-intersection-observer-enty';

const isIntersectionObserverSupported = () =>
  typeof window !== 'undefined' && typeof window.IntersectionObserver !== 'undefined';

export const createIntersectionObserver = (options: IntersectionObserverInit) => {
  const handleInterceptionObserverEntry = (
    resizeObserverEntry: ExtendedIntersectionObserverEntry,
  ) => {
    const { onIntersectionObservation } = resizeObserverEntry.target;
    onIntersectionObservation && onIntersectionObservation(resizeObserverEntry);
  };

  return isIntersectionObserverSupported()
    ? new IntersectionObserver(
        (entries: ExtendedIntersectionObserverEntry[]) =>
          entries.forEach(handleInterceptionObserverEntry),
        options,
      )
    : null;
};
