import React from 'react';
import { createIntersectionObserver } from './create-intersection-observer';
import { Context } from './intersection-observer-context';

type IntersectionObserverProviderProps = {
  children: React.ReactNode;
  options?: IntersectionObserverInit;
};

export const IntersectionObserverProvider = ({ children }: IntersectionObserverProviderProps) => {
  const instance = React.useMemo(() => createIntersectionObserver({ rootMargin: '1500px' }), []);

  return <Context.Provider value={instance}>{children}</Context.Provider>;
};
