import React from 'react';
import { flowRight } from 'lodash';
import { PostTypes } from '@wix/communities-forum-client-commons';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFiltered from '../no-posts-filtered';
import LoadMore from '../load-more';
import { getPostPageSize } from '../../constants/pagination';
import styles from './post-list-feed.component.scss';
import withAuth from '../../hoc/with-auth';
import { useForumWidthContext } from '../responsive-listener';
import { PostListControls } from './post-list-controls.component';
import { RootState } from '../../types/store-types';
import { Category, Post } from '../../types';
import { ForumControllerActions } from '../../types/common-types';
import { getForumContainerBreakpointValue } from '../../selectors/layout-selectors';
import PostListFeedItemVirtualized from '../post-list-feed-item-virtualized/post-list-feed-item-virtualized';
import { IntersectionObserverProvider } from '../intersection-observer/intersection-observer-provider';
import { ResizeObserverProvider } from '../resize-observer/intersection-observer-provider';

type PostListFeedProps = {
  category: Category;
  posts: Post[];
  postTypes: PostTypes[];
  onLikeClick: (postId: string) => void;
  showCreatePostAction: boolean;
  showCategoryLink: boolean;
  hasActiveFilter: boolean;
  showCategoryFilter: boolean;
  loadMore: () => void;
  showLoaderInLoadMore: boolean;
  entityCount: number;
  isLoading: boolean;
  customCtaLabel: string;
  showMemberPosts: boolean;
  emptyStateFragment: React.ReactElement;
  isAuthenticated: boolean;
  postsPerPage: number;
  markPostViewed: (postId: string) => void;
};

const PostListFeed: React.FC<PostListFeedProps> = ({
  category,
  posts,
  postTypes,
  onLikeClick,
  showCreatePostAction,
  showCategoryLink,
  hasActiveFilter,
  showCategoryFilter,
  loadMore,
  showLoaderInLoadMore,
  entityCount,
  isLoading,
  customCtaLabel,
  showMemberPosts,
  emptyStateFragment,
  isAuthenticated,
  postsPerPage,
  markPostViewed,
}) => {
  const width = useForumWidthContext();
  const isVirtualLoading = Math.ceil(posts.length / postsPerPage) >= 10;
  const [reportedViewState] = React.useState<Record<string, boolean>>({});

  const maybeReportView = React.useCallback((postId: string) => {
    if (reportedViewState[postId]) {
      return;
    }

    markPostViewed(postId);
    reportedViewState[postId] = true;
  }, []);

  const onItemMouseOver = React.useCallback((postId: string, hasContentExpandButton: boolean) => {
    if (hasContentExpandButton) {
      return;
    }
    maybeReportView(postId);
  }, []);

  const renderControls = () => {
    const breakpointValue = getForumContainerBreakpointValue(width);
    return (
      <PostListControls
        dataHook="post-list-feed-controls"
        isTop={true}
        showCategoryFilter={showCategoryFilter}
        breakpointValue={breakpointValue}
        isAuthenticated={isAuthenticated}
        showCreatePostAction={showCreatePostAction}
        postTypes={postTypes}
        customCtaLabel={customCtaLabel}
        category={category}
      />
    );
  };
  const showEmptyState = (!isLoading && !posts.length && hasActiveFilter) || emptyStateFragment;
  const emptyState = emptyStateFragment || <NoPostsFiltered noMemberPosts={showMemberPosts} />;

  return (
    <ResizeObserverProvider>
      <IntersectionObserverProvider>
        <div className={styles.container}>
          {renderControls()}
          <LoadMore
            loadMore={loadMore}
            isLoading={showLoaderInLoadMore}
            showButton={isVirtualLoading}
            remainingEntities={entityCount - posts.length}
            addButtonSpacing={false}
          >
            {showEmptyState
              ? emptyState
              : posts.map((post) =>
                  post ? (
                    <div className={styles.item}>
                      <PostListFeedItemVirtualized
                        key={post._id}
                        post={post}
                        category={category}
                        onLikeClick={onLikeClick}
                        showCategoryLink={showCategoryLink}
                        onMouseEnter={onItemMouseOver}
                      />
                    </div>
                  ) : null,
                )}
          </LoadMore>
        </div>
      </IntersectionObserverProvider>
    </ResizeObserverProvider>
  );
};

const mapRuntimeToProps = (
  state: RootState,
  ownProps: any,
  actions: ForumControllerActions,
  host: any,
) => ({
  postsPerPage: getPostPageSize(state, host.style),
  markPostViewed: actions.markPostViewed,
});

export default flowRight(connect(mapRuntimeToProps), withAuth)(PostListFeed);
