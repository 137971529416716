import classNames from 'classnames';
import React from 'react';
import styles from './post-list-feed-item-virtualized-placeholder.scss';

type PostListFeedItemVirtualizedPlaceholderProps = {
  height: number;
};

export const PostListFeedItemVirtualizedPlaceholder: React.FC<
  PostListFeedItemVirtualizedPlaceholderProps
> = ({ height }) => {
  const placeholderClassName = classNames(
    styles.container,
    'forum-card-background-color',
    'forum-card-border-color',
    'post-list-item',
  );

  return (
    <div className={placeholderClassName} style={{ minHeight: height }}>
      <div className={styles.header}>
        <div className={classNames(styles.avatar, styles.skeleton)}></div>
        <div className={classNames(styles.rest, styles.skeleton)}></div>
      </div>
      <div className={classNames(styles.title, styles.skeleton)}></div>
      <div className={classNames(styles.content, styles.skeleton)}></div>
    </div>
  );
};
