import type {
  ExtendedIntersectionObserverEntry,
  ObservedElement,
} from './extended-intersection-observer-enty';
import React, { useContext, useCallback, useRef, useState } from 'react';
import { Context } from './intersection-observer-context';

export const useInterceptObserver = (): [React.RefCallback<ObservedElement | null>, boolean] => {
  const interceptObserver = useContext<IntersectionObserver | null>(Context);

  const [isIntersecting, setIsIntersecting] = useState<boolean>(true);

  const handleInterceptObservation = useCallback(
    (interceptionObservedEntry: ExtendedIntersectionObserverEntry) => {
      setIsIntersecting(interceptionObservedEntry.isIntersecting);
    },
    [setIsIntersecting],
  );

  const ref = useRef<ObservedElement | null>(null);

  const setRef = useCallback(
    (node) => {
      if (ref.current) {
        interceptObserver?.unobserve(ref.current);
        delete ref.current.onIntersectionObservation;
      }

      if (node) {
        node.onIntersectionObservation = handleInterceptObservation;
        interceptObserver?.observe(node);
      }

      ref.current = node;
    },
    [interceptObserver, handleInterceptObservation],
  );

  return [setRef, isIntersecting];
};
