import classNames from 'classnames';
import { throttle } from 'lodash';
import React from 'react';
import styles from './post-list-feed-item-virtualized.scss';
import PostFeedItem from '../post-feed-item/post-feed-item';
import { useInterceptObserver } from '../intersection-observer/use-intersection-observer';
import { PostListFeedItemVirtualizedPlaceholder } from './post-list-feed-item-virtualized-placeholder';
import { useResizeObserver } from '../resize-observer/use-resize-observer';
import { CONTENT_TOGGLE_BUTTON } from '../post-content/data-hooks';

// Expand transition duration is same as in post-content.scss.
// Just to make sure that content is not transitioning on reattachment
const EXPAND_DURATION = 300;

type PostWithId = {
  _id: string;
};

type PostListFeedItemVirtualizedProps = {
  post: PostWithId;
  onLikeClick: (postId: string) => void;
  category: any;
  showCategoryLink: boolean;
  onMouseEnter: (postId: string, hasContentExpandButton: boolean) => void;
};

const PostListFeedItemVirtualized = ({
  post,
  onLikeClick,
  category,
  showCategoryLink,
  onMouseEnter,
}: PostListFeedItemVirtualizedProps) => {
  const [wrapperRef, isVisible] = useInterceptObserver();
  const [postHeight, setPostHeight] = React.useState(0);
  const [isAttaching, setIsAttaching] = React.useState(true);
  const isAttached = isVisible || !postHeight;

  const [postRef, observedEntry] = useResizeObserver({ box: 'border-box' });
  const { blockSize: height } = observedEntry?.borderBoxSize?.[0] || { blockSize: 0 };

  const setPostHeightThrottled = React.useMemo(
    () => throttle((height) => setPostHeight(height), 50, { leading: true, trailing: true }),
    [setPostHeight],
  );

  React.useEffect(() => {
    setPostHeightThrottled(height);
  }, [height]);

  React.useLayoutEffect(() => {
    setIsAttaching(true);

    if (isAttached) {
      setTimeout(() => {
        setIsAttaching(false);
      }, EXPAND_DURATION);
    }
  }, [isAttached]);

  const handleMouseEnter = React.useCallback(
    (e: React.MouseEvent) => {
      const hasContentExpandButton = !!e.currentTarget.querySelector(
        `[data-hook="${CONTENT_TOGGLE_BUTTON}"]`,
      );
      onMouseEnter(post._id, hasContentExpandButton);
    },
    [onMouseEnter],
  );

  return (
    <div
      className={classNames(styles.listItem, 'post-list__post-list-item', {
        [styles.attaching]: isAttaching,
      })}
      id={post._id}
      style={{ minHeight: postHeight }}
      ref={wrapperRef}
      onMouseEnter={handleMouseEnter}
    >
      {isAttached && (
        <PostFeedItem
          forwardedRef={postRef}
          key={post._id}
          post={post}
          category={category}
          onLikeClick={onLikeClick}
          showCategoryLink={showCategoryLink}
        />
      )}
      {!isAttached && <PostListFeedItemVirtualizedPlaceholder height={postHeight} />}
    </div>
  );
};

export default PostListFeedItemVirtualized;
