import { ExtendedResizeObserverEntry } from './extended-resize-observer-enty';

const isResizeObserverSupported = () =>
  typeof window !== 'undefined' && typeof window.ResizeObserver !== 'undefined';

export const createResizeObserver = () => {
  const handleResizeObserverEntry = (resizeObserverEntry: ExtendedResizeObserverEntry) => {
    const { onResizeObservation } = resizeObserverEntry.target;
    onResizeObservation && onResizeObservation(resizeObserverEntry);
  };

  return isResizeObserverSupported()
    ? new ResizeObserver((entries: ExtendedResizeObserverEntry[]) =>
        entries.forEach(handleResizeObserverEntry),
      )
    : null;
};
