import React from 'react';
import { createResizeObserver } from './create-resize-observer';
import { Context } from './resize-observer-context';

type ResizeObserverProviderProps = {
  children: React.ReactNode;
};

export const ResizeObserverProvider = ({ children }: ResizeObserverProviderProps) => {
  const instance = React.useMemo(() => createResizeObserver(), []);

  return <Context.Provider value={instance}>{children}</Context.Provider>;
};
