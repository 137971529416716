import { flowRight } from 'lodash';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import PostHeader from '../post-header';
import PostTitle from '../post-title';
import PostSubTitle from '../post-sub-title';
import { PostSingleRowFooter } from '../post-footer/post-single-row-footer';
import {
  getCategory,
  getMarkedCommentConfig,
} from '../../../common/selectors/categories-selectors';
import {
  getIsCommentsInFeedEnabled,
  getPostFeedCommentsCount,
} from '../../selectors/app-settings-selectors';
import withCardBorderWidth from '../../hoc/with-card-border-width';
import withFontClassName from '../../hoc/with-font-class-name';
import withPermissions from '../../hoc/with-permissions';
import withAuth from '../../hoc/with-auth';
import styles from './post-feed-item.scss';
import { isPostSeen } from '../../services/post-utils';
import { getIsPostNew } from '../../containers/user-activity/user-activity.selectors';
import PostPageWixComments from '../../containers/wix-comments/post-page-wix-comments';
import { HorizontalSeparator } from '../separator';
import { RootState } from '../../types/store-types';
import { ForumControllerActions } from '../../types/common-types';
import { Category, MarkedCommentConfig, Post } from '../../types';
import PostContent from '../post-content';
import { getCommentsPaginationConfig } from './post-feed.utils';

type PostFeedItemProps = {
  category: Category;
  post: Post;
  borderWidth: number;
  titleFontClassName: string;
  contentFontClassName: string;
  showCategoryLink: boolean;
  getIsPostNew: (postId: string) => boolean;
  markedCommentConfig: MarkedCommentConfig;
  isCommentsEnabled: boolean;
  commentsCount: number;
  forwardedRef?: React.Ref<HTMLDivElement>;
  isPostReactionsEnabled: boolean;
};

const COMMENT_TRUNCATE_LIMIT = 50;

const PostFeedItem: React.FC<PostFeedItemProps> = ({
  category,
  post,
  borderWidth,
  titleFontClassName,
  contentFontClassName,
  showCategoryLink,
  getIsPostNew,
  markedCommentConfig,
  isCommentsEnabled,
  commentsCount,
  forwardedRef,
}) => {
  const commentsPaginationConfig = React.useMemo(
    () => getCommentsPaginationConfig(commentsCount, category?.commentsOrdering),
    [commentsCount, category?.commentsOrdering],
  );

  const className = classNames(
    styles.container,
    contentFontClassName,
    'forum-text-color',
    'forum-card-background-color',
    'forum-card-border-color',
    'post-list-item',
  );

  const postLink = `/${category?.slug}/${post?.slug}`;

  return (
    <div
      ref={forwardedRef}
      className={className}
      style={{ borderWidth }}
      role="article"
      data-hook="post-list-item"
    >
      <PostHeader
        post={post}
        isPostNew={getIsPostNew(post._id)}
        category={category}
        type="medium"
        showDateBelowAvatar={false}
        showAvatarBadges
        showPinIcon={true}
        showLockIcon={false}
        showBestAnswer={true}
        showMoreButton={true}
        origin="posts-feed"
        showNewPostIndicator={true}
      />
      <div className={styles.titleContainer}>
        <PostTitle
          className={classNames(titleFontClassName, 'forum-text-color')}
          isPostSeen={isPostSeen(post)}
          type="medium"
          title={post.title}
          to={postLink}
          post={post}
          category={category}
          showBestAnswer={false}
        />
        <PostSubTitle
          className={styles.subtitle}
          post={post}
          type="medium"
          category={category}
          showCategoryLink={showCategoryLink}
        />
      </div>
      <PostContent
        className={styles.content}
        post={post}
        type={PostContent.FEED_PAGE}
        contentFontClassName={contentFontClassName}
      />
      <PostSingleRowFooter
        category={category}
        post={post}
        origin="feedItem"
        isCommentsEnabled={isCommentsEnabled}
        className={styles.postFooter}
        actionsClassName={styles.postActions}
        metadataClassName={styles.postMetadata}
        shouldFocusCommentBox={isCommentsEnabled}
      />
      {isCommentsEnabled && (
        <>
          <div className={styles.commentsWrapper}>
            <HorizontalSeparator className={styles.commentsSeparator} theme={undefined} />
            <PostPageWixComments
              category={category}
              post={post}
              hideHeaderDivider
              markedCommentConfig={markedCommentConfig}
              paginationConfig={commentsPaginationConfig}
              contentTruncationLimit={COMMENT_TRUNCATE_LIMIT}
            />
          </div>
        </>
      )}
    </div>
  );
};

const mapRuntimeToProps = (
  state: RootState,
  ownProps: any,
  actions: ForumControllerActions,
  host: any,
) => {
  const category = getCategory(state, ownProps.post?.categoryId);
  return {
    category,
    isCommentsEnabled: getIsCommentsInFeedEnabled(state, host.style),
    commentsCount: Number(getPostFeedCommentsCount(state, host.style)),
    getIsPostNew: getIsPostNew(state),
    markedCommentConfig: getMarkedCommentConfig(category),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withCardBorderWidth,
  withFontClassName,
  withPermissions,
  withAuth,
)(PostFeedItem);
