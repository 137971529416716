import type { ExtendedResizeObserverEntry, ObservedElement } from './extended-resize-observer-enty';
import React, { useContext, useCallback, useRef, useState } from 'react';
import { Context } from './resize-observer-context';

// This is copy of https://github.com/envato/react-resize-observer-hook/ with SSR support added

const boxOptions = {
  BORDER_BOX: 'border-box',
  CONTENT_BOX: 'content-box',
  DEVICE_PIXEL_CONTENT_BOX: 'device-pixel-content-box',
};

export const useResizeObserver = (
  options: ResizeObserverOptions = {},
): [React.RefCallback<ObservedElement | null>, ExtendedResizeObserverEntry | null] => {
  const resizeObserver = useContext<ResizeObserver | null>(Context);

  const [observedEntry, setObservedEntry] = useState<ExtendedResizeObserverEntry | null>(null);

  const handleResizeObservation = useCallback(
    (resizeObserverEntry: ExtendedResizeObserverEntry) => {
      if (!observedEntry) {
        return setObservedEntry(resizeObserverEntry);
      }

      let isIdentical = true;

      switch (options.box) {
        case boxOptions.BORDER_BOX:
          isIdentical = resizeObserverEntry.borderBoxSize.every(
            (boxSize, index) =>
              boxSize.inlineSize === observedEntry.borderBoxSize[index].inlineSize &&
              boxSize.blockSize === observedEntry.borderBoxSize[index].blockSize,
          );
          break;

        case boxOptions.CONTENT_BOX:
          isIdentical = resizeObserverEntry.contentBoxSize.every(
            (boxSize, index) =>
              boxSize.inlineSize === observedEntry.contentBoxSize[index].inlineSize &&
              boxSize.blockSize === observedEntry.contentBoxSize[index].blockSize,
          );
          break;

        case boxOptions.DEVICE_PIXEL_CONTENT_BOX:
          isIdentical = resizeObserverEntry.devicePixelContentBoxSize.every(
            (boxSize, index) =>
              boxSize.inlineSize === observedEntry.devicePixelContentBoxSize[index].inlineSize &&
              boxSize.blockSize === observedEntry.devicePixelContentBoxSize[index].blockSize,
          );
          break;

        default:
          if (
            resizeObserverEntry.contentRect.width !== observedEntry.contentRect.width ||
            resizeObserverEntry.contentRect.height !== observedEntry.contentRect.height
          ) {
            isIdentical = false;
          }
      }

      if (!isIdentical) {
        setObservedEntry(resizeObserverEntry);
      }
    },
    [options.box, observedEntry],
  );

  const ref = useRef<ObservedElement | null>(null);

  const setRef = useCallback(
    (node) => {
      if (ref.current) {
        resizeObserver?.unobserve(ref.current);
        delete ref.current.onResizeObservation;
      }

      if (node) {
        node.onResizeObservation = handleResizeObservation;
        resizeObserver?.observe(node, options);
      }

      ref.current = node;
    },
    [resizeObserver, handleResizeObservation, options],
  );

  return [setRef, observedEntry];
};
